import axios from "axios";
export const SET_CURRENCY = "SET_CURRENCY";

export const setCurrency = currencyName => {
  return dispatch => {
    axios
      .get(`${process.env.REACT_APP_HOST}/api/content/`)
      .then(({data: response}) => {
        if (!Array.isArray(response.data) || response.data.length === 0){
          console.log(response);
          return;
        }
        let defaultCurrencyName = "hkd";
        if (response.currency?.primary) defaultCurrencyName = response.currency?.primary;
        if (!currencyName) currencyName = defaultCurrencyName;

        let exchangeRates = response.data[0].exchangeRate;
        if (!exchangeRates) {
          dispatch({
            type: SET_CURRENCY,
            payload: { currencyName, currencyRate: 1, defaultCurrencyName }
          });
          return;
        }
        let currencyRate = response.data[0].exchangeRate[currencyName];
        if (!currencyRate){
          dispatch({
            type: SET_CURRENCY,
            payload: { currencyName, currencyRate: 1, defaultCurrencyName }
          });
          return;
        }
        dispatch({
          type: SET_CURRENCY,
          payload: { currencyName, currencyRate, defaultCurrencyName }
        });
      })
      .catch(err => {
        console.log("Error: ", err);
      });
    // axios
    //   .get(`https://api.exchangeratesapi.io/latest?base=USD`)
    //   .then(response => {
    //     const rates = response.data.rates;
    //     let currencyRate = 0;
    //     for (const rate in rates) {
    //       if (rate === currencyName) {
    //         currencyRate = rates[rate];
    //       }
    //     }
    //     dispatch({
    //       type: SET_CURRENCY,
    //       payload: { currencyName, currencyRate }
    //     });
    //   })
    //   .catch(err => {
    //     console.log("Error: ", err);
    //   });
  };
};
