export default {
  loading: 'Loading',
  homepage: '主页',
  search: '搜寻',
  default: '预设',
  save: '储存',
  noRecord: '没有纪录',
  add: '新增',
  date: '日期',
  delete: '删除',
  navMenu: {
    login: '登入',
    register: '注册',
    myAccount: '我的帐户',
    logout: '登出',
    quantity: '数量',
    sku: '款式',
    option: '选项',
    total: '总计',
    viewCart: '检视购物车',
    checkout: '结算',
    noItemInCart: '购物车内没有货物',
    aboutUs: '关于我们',
  },
  footer: {
    aboutUs: '关于我们',
    usefulLinks: '有用连结',
    disclaimer: '免责声明',
    privacyPolicy: '私隐政策',
    tnc: '使用条款',
    guide: '购物指南',
    subscribeUs: '关注我们',
    Facebook: 'Facebook',
    Youtube: 'Youtube',
    Instagram: 'Instagram',
  },
  home: {
    metaTitle: 'Panashop',
    metaName: 'Panashop',
    metaContent: 'Panashop',
    buyNow: '立即选购',
    latestProducts: '最新产品',
    latestProductsSubTitle: '送上每月最新产品',
    new: '新',
    selectOptions: '选择款式',
    addToCart: '新增至购物车',
    addedToCart: '已加到购物车',
    outOfStock: '售罄',
    view: '检视',
    addedToWishlist: '已加到最爱',
    addToWishlist: '加到最爱',
    blogTitle: '最新消息',
    writtenBy: '编',
    viewMoreProducts: '更多產品',
  },
  productList: {
    metaTitle: 'Panashop',
    metaName: 'Panashop',
    metaContent: 'Panashop',
    allProducts: '所有产品',
    priceLowToHigh: '价钱 - 低至高',
    priceHighToLow: '价钱 - 高至低',
    updateTimeNewToOld: '更新时间 - 新到旧',
    updateTimeOldToNew: '更新时间 - 旧到新',
    categories: '产品种类',
    noCategories: '所有',
    buyNow: '立即选购',
    new: '新',
    selectOptions: '选择款式',
    addToCart: '新增至购物车',
    addedToCart: '已加到购物车',
    outOfStock: '售罄',
    view: '检视',
    addedToWishlist: '已加到最爱',
    addToWishlist: '加到最爱',
    addedToCompare: '已加到比较',
    addToCompare: '加到比较',
  },
  productDetails: {
    metaTitle: 'Panashop | 产品详情',
    metaName: 'Panashop',
    metaContent: 'Panashop',
    productDetails: '产品详情',
    sku: '款式',
    option: '选项',
    buyNow: '立即选购',
    addToCart: '加入购物车',
    outOfStock: '售罄',
    additionalInfo: '规格',
    alsoBuy: '通常也会买',
    quantity: '件数',
    packageTotal: '套装总价',
    relatedProducts: '相关产品',
  },
  loginRegister: {
    metaTitle: 'Panashop | 登入 / 注册',
    metaName: 'Panashop',
    metaContent: 'Panashop',
    loginRegister: '登入｜注册',
    login: '登入',
    register: '注册',
    email: '电邮地址',
    password: '密码',
    rememberMe: '记住我',
    forgotPassword: '忘记密码',
    firstName: '名字',
    lastName: '姓氏',
    phone: '电话',
    resetPasswordMsg: '如您所输入的电邮地址符合我们的系统纪录，我们将会发送重置密码之电邮到您的电邮地址。',
    validateAccountMsg: '电邮地址认证成功',
  },
  myAccount: {
    metaTitle: 'Panashop | 我的帐户',
    metaName: 'Panashop',
    metaContent: 'Panashop',
    myAccount: '我的帐户',
    personalInfo: '个人资料',
    deliveryAddress: '送货地址',
    orderRecord: '订单记录',
    personalInfoTitle: '你的个人详情',
    firstName: '名字',
    lastName: '姓氏',
    phone: '电话',
    email: '电邮地址',
    changePassword: '更改密码',
    newPassword: '新密码',
    confirmPassword: '确认新密码',
    addNewAddress: '新增送货地址',
    addressLine1: '街道地址',
    addressLine2: '街道地址 2',
    addressLine3: '城市',
    stateProvince: '州 / 省',
    zipCode: '邮递编号',
    countryRegion: '国家 / 地区',
    order: '订单',
    orderId: '订单编号',
    orderStatus: '订单状态',
    paymentStatus: '付款状态',
    deliveryStatus: '送货状态',
    total: '总计',
    details: '详情',
    receiverName: '收件人姓名',
    contactPhone: '联络电话',
    image: '图片',
    productName: '产品名字',
    unitPrice: '单价',
    quantity: '数量',
    subTotal: '小计',
    sku: '款式',
    option: '选项',
  },
  cart: {
    metaTitle: 'Panashop | 购物车',
    metaName: 'Panashop',
    metaContent: 'Panashop',
    cart: '购物车',
    yourCart: '你的购物车',
    image: '图片',
    productName: '产品名字',
    unitPrice: '单价',
    quantity: '数量',
    subTotal: '小计',
    sku: '款式',
    option: '选项',
    continueShopping: '继续购物',
    clearCart: '清空购物车',
    useCoupon: '使用优惠码',
    inputCouponCode: '输入优惠码',
    applyCouponCode: '应用优惠码',
    cartTotal: '购物车总计',
    totalDiscount: '总折扣',
    total: '总计',
    continueCheckout: '继续结算',
    noItemInCart: '购物车内没有货物',
    buyNow: '立即选购',
  },
  checkout: {
    metaTitle: 'Panashop | 结算',
    metaName: 'Panashop',
    metaContent: 'Panashop',
    checkout: '结算',
    billingDetails: '帐单详情',
    firstName: '名字',
    lastName: '姓氏',
    phone: '电话',
    email: '电邮地址',
    companyName: '公司名称',
    addressLine1: '街道地址',
    addressLine2: '街道地址 2',
    addressLine3: '城市',
    stateProvince: '州 / 省',
    zipCode: '邮递编号',
    countryRegion: '国家 / 地区',
    additionalInfo: '额外资讯',
    otherRemarks: '其他备注',
    yourOrder: '您的订单',
    product: '产品',
    total: '总计',
    delivery: '送货',
    freeShipping: '免费送货',
    submitOrder: '提交订单',
    discount: '折扣',
    totalDiscount: '总折扣',
    noItemInCart: '购物车内没有货物',
    buyNow: '立即选购',
    shippingMethod: '送货方法',
    shippingGeneral: '物流送货',
    shippingPickUp: 'PanaShop 自取',
  },
  paymentNotification: {
    metaTitle: 'Panashop | 付款结果',
    metaName: 'Panashop',
    metaContent: 'Panashop',
    paymentNotification: '付款结果',
    backToHome: '返回主页',
    orderSuccessTitle: '成功下单',
    orderSuccessMsg: '感谢您的购买！我们已收到您的订单，请检查您的电子邮件以获取更多信息。',
    orderCancelledTitle: '订单取消',
    orderCancelledMsg: '您的订单已被取消，请联系客户服务以获取更多信息。',
    orderPendingTitle: '待处理订单',
    orderPendingMsg: '感谢您的购买！我们已收到您的订单，请检查您的电子邮件以获取更多信息。',
  },
  aboutUs: {
    metaTitle: 'Panashop',
    metaName: 'Panashop',
    metaContent: 'Panashop',
    aboutUs: '关于我们',
  },
  blog: {
    metaTitle: 'Panashop',
    metaName: 'Panashop',
    metaContent: 'Panashop',
    relatedProducts: '相关产品',
    blogPost: 'Blog Post'
  },
  wishlist: {
    metaTitle: 'Panashop',
    metaName: 'Panashop',
    metaContent: 'Panashop',
    wishlist: '收藏',
    image: '图片',
    productName: '产品名字',
    unitPrice: '单价',
    selectOptions: '选择款式',
    addToCart: '新增至购物车',
    addedToCart: '已加到购物车',
    outOfStock: '售罄',
    continueShopping: '继续购物',
  },
  compare: {
    metaTitle: 'Panashop',
    metaName: 'Panashop',
    metaContent: 'Panashop',
    compare: '比较',
    product: '产品',
    unitPrice: '单价',
    description: '产品描述',
    sku: '款式',
    option: '选项',
    additionalInfo: '更多资料',
    view: '检视',
    continueShopping: '继续购物',
  },
  resetPassword: {
    metaTitle: 'Panashop',
    metaName: 'Panashop',
    metaContent: 'Panashop',
    resetPassword: '重置密码',
    newPassword: '新密码',
    confirmPassword: '确认新密码',
  },
  alerts: {
    accountNotFound: '帐户不存在',
    wrongEmail: '请提供正确的电邮地址',
    wrongConfirmPassword: '新密码与确认密码不符',
    wrongPasswordFormat: '请提供正确的密码，密码需至少包含8个字符，以数字及英文字母组成 (包括1个大阶字母)',
    wrongPhoneFormat: '请提供正确的手提号码',
    fillNecessaryInfo: '请填写全部资料',
    updateAccountInfoSuccess: '资料已被更改',
    updatePasswordSuccess: '密码已被更改',
    referencePriceRemarks: '参考价钱',
    loginFailed: '电邮地址或密码不正确',
  },
};
