export const ADD_TO_WISHLIST = "ADD_TO_WISHLIST";
export const UPDATE_WISHLIST = "UPDATE_WISHLIST";
export const DELETE_FROM_WISHLIST = "DELETE_FROM_WISHLIST";
export const DELETE_ALL_FROM_WISHLIST = "DELETE_ALL_FROM_WISHLIST";

// add to wishlist
export const addToWishlist = (item, addToast, token) => {
  return dispatch => {
    fetch(
      `${process.env.REACT_APP_HOST}/api/wishlist/toggle?access_token=${token}`,
      {
        method: "POST",
        headers: { 'Content-Type': 'application/json' },
        body: JSON.stringify({
          option: item.skus[0].options[0]._id,
          action: 'add',
        })
      }
    )
      .then(res => res.json())
      .then(response => {
        console.log({action: 'addToWishlist', response});
        if (response.status !== 1) {
          console.log({action: 'addToWishlist', response});
          dispatch({type: UPDATE_WISHLIST, payload: []});
          return;
        }
        if (addToast) {
          addToast("Added To Wishlist", {
            appearance: "success",
            autoDismiss: true
          });
        }
        fetchWishlist(token, dispatch);
      })
      .catch(error => console.log({action: 'addToWishlist', error}));
    // dispatch({ type: ADD_TO_WISHLIST, payload: item });
  };
};

// update wishlist
export const updateWishlist = (token) => {
  return dispatch => {
    if (!token) {
      dispatch({type: UPDATE_WISHLIST, payload: []});
      return;
    }
    fetchWishlist(token, dispatch);
  };
};

const fetchWishlist = (token, dispatch) => {
  fetch(
    `${process.env.REACT_APP_HOST}/api/me/wishlist?access_token=${token}`,
    {
      method: "GET"
    }
  )
    .then(res => res.json())
    .then(response => {
      if (!Array.isArray(response.data)) {
        console.log({action: 'updateWishlist', response});
        dispatch({type: UPDATE_WISHLIST, payload: []});
        return;
      }
      let items = response.data.map(item => {
        item.sku.option = item.option;
        item.product.sku = item.sku;
        return item.product;
      });
      dispatch({type: UPDATE_WISHLIST, payload: items});
    })
    .catch(error => console.log({action: 'updateWishlist', error}));
}

// delete from wishlist
export const deleteFromWishlist = (item, addToast, token) => {
  return dispatch => {
    fetch(
      `${process.env.REACT_APP_HOST}/api/wishlist/toggle?access_token=${token}`,
      {
        method: "POST",
        headers: { 'Content-Type': 'application/json' },
        body: JSON.stringify({
          option: item.sku.option._id,
          action: 'remove',
        })
      }
    )
      .then(res => res.json())
      .then(response => {
        console.log({action: 'addToWishlist', response});
        if (response.status !== 1) {
          console.log({action: 'addToWishlist', response});
          dispatch({type: UPDATE_WISHLIST, payload: []});
          return;
        }
        if (addToast) {
          addToast("Removed From Wishlist", {
            appearance: "error",
            autoDismiss: true
          });
        }
        fetchWishlist(token, dispatch);
      })
      .catch(error => console.log({action: 'addToWishlist', error}));
    // dispatch({ type: ADD_TO_WISHLIST, payload: item });
  };
};

//delete all from wishlist
export const deleteAllFromWishlist = addToast => {
  return dispatch => {
    if (addToast) {
      addToast("Removed All From Wishlist", {
        appearance: "error",
        autoDismiss: true
      });
    }
    dispatch({ type: DELETE_ALL_FROM_WISHLIST });
  };
};
